@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
  font-family: "IBM Plex Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "IBM Plex Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
